<script>
import axios from 'axios'

export default {
    name: 'LoginApp',
    data() {
        return {
            email: '',
            password: '',
            showErrorDialog: false,
            showErrorMessage: '',
            isSuccessLogin: false,
            isSendingRequest: false,
            backgroundImage: `${document.location.origin}/image/login.jpg`
        }
    },
    computed: {
        emailRules() {
            const numberRegexp = new RegExp(/^[A-Za-z0-9_.\-?+!@$]{4,}@[a-zA-Z0-9\-]{3,}\.[a-zA-Z]{2,}$/g)

            return [
                (v) => !!v || 'Email is required', // email is required,
                (v) => !!v.match(numberRegexp) || 'Email is invalid'
            ]
        },
        passwordRules() {
            return [(v) => !!v || 'Password is required']
        }
    },
    methods: {
        setErrorData(error = false, message = '') {
            this.showErrorDialog = error
            this.showErrorMessage = message
        },
        login() {
            if (!this.$refs.form.validate()) {
                return
            }

            const {email, password} = this

            this.isSendingRequest = true

            return axios
                .post('/api/login', {email, password})
                .then(() => {
                    console.log('Success Login')
                    this.isSuccessLogin = true;
                    this.isSendingRequest = false;
                    window.location.href = '/dashboard'
                })
                .catch((error) => {
                    if (error.response.status === 401 || error.response.status === 404) {
                        this.setErrorData(true, error.response.statusText)
                    } else if (error.response.status === 500) {
                        console.log(error.response.statusText)
                        this.setErrorData(true, 'Some error. Please contact support.')
                    }
                    this.isSendingRequest = false
                })
        }
    }
}
</script>
<template>
    <v-app>
        <v-main
            :style="{
                'background-image': `url(${backgroundImage})`
            }"
            tag="div" class="background">
            <v-container class="fill-height justify-center flex-column">
                <img src="/logo_MM_400_white.png" alt="" class="mb-4 logo"/>
                <v-card style="background-color: #ffffffab!important;" :width="$vuetify.breakpoint.xsOnly ? '100%' : '400px'">
                    <v-card-title dark class="card-title">Login</v-card-title>
                    <v-form ref="form" lazy-validation class="pa-4">
                        <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="Email"
                            type="email"
                            required
                        ></v-text-field>

                        <v-text-field
                            v-model="password"
                            :rules="passwordRules"
                            label="Password"
                            type="password"
                            required
                        ></v-text-field>
                    </v-form>
                    <v-card-actions class="pa-4">
                        <v-btn
                            :color="isSuccessLogin ? 'success' : 'primary'"
                            width="100%"
                            :loading="isSendingRequest"
                            @click="login"
                        >
                            Login
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-container>
            <v-dialog v-model="showErrorDialog" max-width="400">
                <v-card>
                    <v-card-title class="flex-column">
                        <v-icon size="96" color="error" class="mb-4">error_outline</v-icon>
                        <span> Error</span>
                    </v-card-title>
                    <v-card-text class="text-center">
                        {{ showErrorMessage }}
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn color="secondary" text @click="() => setErrorData()"
                        >Cancel
                        </v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>
<style scoped>
/*.background {*/
/*    background-image: url("/image/login.png");*/
/*    !*background: rgb(64, 106, 255);*!*/
/*    !*background: linear-gradient(96.32deg, #334dcb -14.21%, #5714a3 102.68%);*!*/
/*}*/

.logo {
    max-height: 40px;
}
</style>
<style>
html {
    overflow-y: auto;
}
</style>
