import Vue     from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, { directives: { ripple } })

// const theme = {
//     primary: '#E91E63',
//     secondary: '#9C27b0',
//     accent: '#e91e63',
//     info: '#00CAE3',
//     success: '#4CAF50',
//     warning: '#FB8C00',
//     error: '#FF5252',
// }
const themeLight = {
    primary: '#9c27b0',
    secondary: '#3f51b5',
    accent: '#673ab7',
    error: '#e91e63',
    warning: '#ff9800',
    info: '#00bcd4',
    success: '#4caf50'
}
const themeDark = {
    primary: '#2196f3',
    secondary: '#00bcd4',
    accent: '#009688',
    error: '#e91e63',
    warning: '#ff9800',
    info: '#607d8b',
    success: '#4caf50'
}

export default new Vuetify({
    breakpoint: { mobileBreakpoint: 960 },
    icons: {
        values: { expand: 'mdi-menu-down' },
    },
    theme: {
        dark: false,
        themes: {
            dark: themeDark,
            light: themeLight,
        },
    },
})
